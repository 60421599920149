var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"default-modal",attrs:{"visible":_vm.visible,"title":"修改部门","width":600},on:{"update:visible":function($event){_vm.visible=$event},"cancel":_vm.handleCancel}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form-model',_vm._b({ref:"form",staticStyle:{"width":"100%"},attrs:{"model":_vm.ruleForm,"rules":_vm.rules}},'a-form-model',{
            labelCol: {
                span: 5
            },
            wrapperCol: {
                span: 18
            },
        },false),[_c('a-form-model-item',{attrs:{"label":"部门","prop":"orgId"}},[_c('a-cascader',{staticStyle:{"width":"100%"},attrs:{"change-on-select":"","placeholder":"请选择","field-names":{ label: 'name', value: 'id', children: 'children' },"options":_vm.options},model:{value:(_vm.ruleForm.orgId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "orgId", $$v)},expression:"ruleForm.orgId"}})],1)],1)],1),_c('template',{slot:"footer"},[_c('div',{staticClass:"btns-ctn"},[_c('a-button',{staticClass:"concel-btn",on:{"click":_vm.handleCancel}},[_vm._v(" 取消 ")]),_c('a-button',{class:[_vm.loading ? '' : 'confirm-btn'],attrs:{"loading":_vm.loading,"type":"primary","disabled":_vm.loading},on:{"click":_vm.handleConfirmClick}},[_vm._v(" 确定 ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }