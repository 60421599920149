<template>
    <a-modal class="default-modal" :visible.sync="visible" title="修改部门" :width="600" @cancel="handleCancel">
        <a-spin :spinning="loading">
            <a-form-model style="width: 100%;" ref="form" :model="ruleForm" :rules="rules" v-bind="{
                labelCol: {
                    span: 5
                },
                wrapperCol: {
                    span: 18
                },
            }">
                <a-form-model-item label="部门" prop="orgId">
                    <a-cascader change-on-select style="width: 100%;" placeholder="请选择"
                        :field-names="{ label: 'name', value: 'id', children: 'children' }" :options="options"
                        v-model="ruleForm.orgId" />
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel" style="">
                    取消
                </a-button>
                <a-button :loading="loading" :class="[loading ? '' : 'confirm-btn']" type="primary" :disabled="loading"
                    @click="handleConfirmClick">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import { getOrgTree, editMyOrg } from '@/api/others';
import store from '../../store';
import { message } from 'ant-design-vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }

        const handleConfirmClick = () => {
            loading.value = true;
            form.value && form.value.validate(async (valid) => {
                if (valid) {
                    const res = await editMyOrg({
                        orgId: ruleForm.value.orgId[ruleForm.value.orgId.length - 1]
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success({
                            content: res.message
                        });
                        context.emit('onUpOrg')
                    }
                }
            })
            loading.value = false;
        }

        const ruleForm = ref({
            orgId: []
        })

        const rules = ref({
            orgId: [{
                required: true,
                message: '请选择'
            }]
        })

        const orgId = ref('');

        const loading = ref(false);

        const init = async () => {
            loading.value = true;
            ruleForm.value.orgId = [];
            const res = await getOrgTree();
            if (res.code === 200) {
                options.value = res.data;
                orgId.value = store.state.userInfo.orgId;
                for (let i = 0; i < options.value.length; ++i) {
                    if (getOrgArr(options.value[i])) {
                        ruleForm.value.orgId.unshift(options.value[i].id);
                        break;
                    }
                }
            }
            loading.value = false;
        }

        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                init();
            }
        })

        const options = ref([]);

        const getOrgArr = (item) => {
            if (item.id == orgId.value) {
                return true;
            }
            if (!item.children || (item.children && item.children.length == 0)) {
                return false;
            }
            for (let i = 0; i < item.children.length; ++i) {
                if (getOrgArr(item.children[i])) {
                    ruleForm.value.orgId.unshift(item.children[i].id);
                    return true;
                }
            }
            return false;
        }

        const form = ref(null)

        return {
            handleCancel,
            handleConfirmClick,
            options,
            rules,
            form,
            loading,
            ruleForm
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    width: 100%;

    .confirm-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
    }

    .concel-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
        background: #EEEEEE;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 24px;
        &:hover {
            opacity: 0.8;
        }
    }
}
</style>