<template>
	<div style="width: 100%;">
		<upPass v-if="upPassVisible" :visible.sync="upPassVisible" @onUpPass="onUpPass"></upPass>
		<upEmail v-if="upEmailVisible" :visible.sync="upEmailVisible" @onUpEmail="onUpEmail"></upEmail>
		<upPos v-if="upPosVisible" :visible.sync="upPosVisible" :posId="posId" @onUpPos="onUpPos"></upPos>
		<upOrg :visible.sync="upOrgVisible" @onUpOrg="onUpOrg"></upOrg>
		<div class="title">
			基本资料
		</div>
		<div>
			<div style="display: flex;margin-left: 92px;">
				<div
					style="display: flex;flex-direction: column;align-items: center;margin-right: 50px;position: relative;">
					<div @click="show = !show"  style="cursor: pointer;">
						<div
							style="position: absolute; width: 120px;height: 22px;background: rgba(8, 16, 27, 0.65);top: 120px;z-index: 100;left: 5px;">
							<a-icon type="upload" />
						</div>
						<!-- <img src="../../../assets/矩形 46.png" style="position: absolute;top: 25px;left: 5px;"> -->

						<div
						v-if="!head"
							style="cursor: pointer; width: 130px;height: 130px;border-radius: 2px;margin: 20px 0;border: 1px dashed #e4e4e4;">
							<div
								style="background: #00a870; font-size: 32px; height: 95%; width: 95%; margin: 2.5%; border-radius: 4px; display: flex; align-items: center; justify-content: center;">
								{{ userInfo.name[userInfo.name.length - 1] }}
							</div>
						</div>
						<div
						 v-if="head" @click="show = !show"
							style="cursor: pointer; width: 130px;height: 130px;border-radius: 2px;margin: 20px 0;border: 1px dashed #e4e4e4;">
							<img v-if="head" @click="show = !show"
								style="cursor: pointer; width: 120px;height: 120px;border-radius: 2px;"
								:src="head" title="点击修改头像">
						</div>

						
					</div>

					
					<a-popconfirm title="请确认是否修改此头像?" ok-text="是" cancel-text="否" @confirm="updateHead">
						<div
							class="modify-avatar-btn"
							style="cursor: pointer; width: 88px;height: 28px;border-radius: 2px;display: flex;justify-content: center;align-items: center;font-size: 12px;" v-show="newHead">
							确认修改头像</div>
					</a-popconfirm>
					<my-upload @crop-success="cropSuccess" v-show="show" :width="200" :height="200" img-format="jpg"
						:size="size" langType='zh' :noRotate="false" field="input"></my-upload>
				</div>
				<div>
					<div style="text-align: left;margin-top: 20px;color: rgba(0, 0, 0, 0.85);">
						<div class="box">
							<p style="font-size: 16px;color: rgba(0, 0, 0, 0.85); width: 0; flex: 1;">账户：{{ isShowInfo ?  decryptUserInfo.name :  userInfo.name }}
							 
							</p>
							<div style="font-size: 18px; margin: 0px 20px; cursor: pointer;" class="show-info-op">
								<a-tooltip :title="isShowInfo ? `点击隐藏` : `点击显示`">
									<a-icon type="eye" v-show="!isShowInfo"  @click="toggleInfo"/>
									<a-icon type="eye-invisible" v-show="isShowInfo"  @click="toggleInfo"/>
								</a-tooltip>
							</div>
							<div @click="upPass"
								style="cursor: pointer; font-size: 14px;color: #1890FF;width: 80px;padding: 0px 5px; text-align: left;">
								修改密码</div>
						</div>
						<div class="box">
							<p style="font-size: 16px;color: rgba(0, 0, 0, 0.85);">手机号：{{ isShowInfo ?  decryptUserInfo.phone :  userInfo.phone }}</p>
							<!-- <div style="font-size: 14px;color: red;width: 80px; padding: 0px 5px; text-align: left;">暂无法更改</div> -->
						</div>
						<div class="box">
							<p style="font-size: 16px;color: rgba(0, 0, 0, 0.85);">邮箱：{{ isShowInfo ? decryptUserInfo.email :  userInfo.email }}</p>
							<div style="font-size: 14px;width: 80px; text-align: left; ">
								<div :style="(!userInfo.email && userInfo.email !== undefined) && directionMask ? { zIndex: 199, position: 'relative' } : {}">
									<div v-if="!userInfo.email && userInfo.email !== undefined && directionMask"
										style="min-width: 350px; min-height: 50px;  position: absolute; left: -220px; top: -75px; border-radius: 8px; padding: 12px; color: rgba(0, 0, 0, 0.65); background: #ffffff;">
										请完善信息，绑定邮箱后，才可被邀请至项目组。
									</div>
									<div v-if="!userInfo.email && userInfo.email !== undefined && directionMask"
										style="position: absolute; border-left: 25px solid transparent;border-right: 25px solid transparent; border-top: 20px solid white; top: -25px; left: 10px;">
									</div>
									<div :style="{cursor: `pointer`,color: `#1890FF`, width: 'fit-content', padding: '0px 5px', borderRadius: '4px', background: (!userInfo.email && userInfo.email !== undefined) && directionMask ? '#ffffff' : 'transparent'}" @click="upEmail">
										{{ userInfo.email ? '修改邮箱' : '绑定邮箱' }}
									</div>
								</div>
								<div v-if="!userInfo.email && userInfo.email !== undefined && directionMask"
									style="position: absolute; width: calc(100%); height: 130%; top: 0; left: 0; background: rgba(0, 0, 0, 0.35); z-index: 10; overflow: hidden; "
									@click="directionMaskClick">
								</div>

							</div>
						</div>
						<div class="box">
							<div
								style="font-size: 16px;color: rgba(0, 0, 0, 0.85);width: 350px;display:flex;">
								<p style="font-size: 16px;color: rgba(0, 0, 0, 0.85);">部门：{{ userInfo.orgName }}</p>

							</div>
							<div @click="upOrg"
								style="cursor: pointer; font-size: 14px;color: #1890FF;width: 80px; text-align: left;padding: 0px 5px;">
								修改部门</div>
						</div>
						<div class="box">
							<div
								style="font-size: 16px;color: rgba(0, 0, 0, 0.85);width: 350px;display:flex;">
								<p style="font-size: 16px;color: rgba(0, 0, 0, 0.85);">职位：{{ userInfo.posName }}</p>

							</div>
							<div @click="upPos"
								style="cursor: pointer; font-size: 14px;color: #1890FF;width: 80px; text-align: left;padding: 0px 5px;">
								修改职位</div>
						</div>
						<!-- <div class="box">
							<div
								style="font-size: 16px;color: rgba(0, 0, 0, 0.85);width: 350px;display:flex;">
								<p style="font-size: 16px;color: rgba(0, 0, 0, 0.85);">身份：{{ userInfo.posName }}</p>

							</div>
							<div @click="upPos"
								style="cursor: pointer; font-size: 14px;color: #1890FF;width: 80px; text-align: left;padding: 0px 5px;">
								修改身份</div>
						</div> -->
					</div>
					<div style="width: 196%;height: 1px;background-color: #EBEBEB;margin: 19px 0;"></div>
					<div style="font-size: 16px;color: rgba(0, 0, 0, 0.65);text-align: left;font-size: 16px;">
						登录时间：{{ userInfo.lastLoginTime }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'
import {  getUserInfo } from '../../../api/anotherIndex'
import { uploadUserPhoto, getDecryptUserInfo } from '../../../api/others';
import upPass from '../../../components/core/upPass.vue'
import upEmail from '../../../components/core/upEmail.vue'
import upPos from '../../../components/core/upPos.vue'
import upOrg from '../../../components/core/upOrg.vue'
import { userInfo } from 'os';
export default {
	inject: ['reload'],
	data() {

		return {
			//userInfo: this.$store.state.userInfo,
			show: false,
			posId: 0,
			size: 150,
			head: this.$store.state.userInfo.photoMedia ? "" + this.$store.state.userInfo.photoMedia : undefined,
			directionMask: false,
			newHead: '',
			upOrgVisible: false,
			isShowInfo: false,	// 是否显示隐藏信息
			isLoadingInfo: false,	 // 是否正在加载隐藏信息
			decryptUserInfo: null,	// 未脱敏的用户信息

			upEmailVisible: false,	// 更新email可见变量

			upPosVisible: false,	// 更新职位验证码

			upPassVisible: false,	// 更新密码对话框可见变量
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.userInfo
		}
	},
	components: {
		myUpload,
		upPass,
		upEmail,
		upPos,
		upOrg
	},
	created() {
		this.getUserInfo()
	},
	watch: {
		directionMask: {
			handler(e) {
				if(e) {
					document.documentElement.style.overflow='hidden';
				}
				else {
					document.documentElement.style.overflow='auto';
				}
			}
		},
	},
	mounted() {
		let headerImgClose1 = document.querySelector(".vicp-operate")
		let headerImgClose2 = document.querySelector(".vicp-close")
		var that = this
		headerImgClose1.onclick = function () {
			that.show = false
		}
		headerImgClose2.onclick = function () {
			that.show = false
		}
	},
	methods: {
		cropSuccess(imgDataUrl, field) {
			//console.log(imgDataUrl);
			this.show = !this.show
			this.head = imgDataUrl
			this.newHead = imgDataUrl;
		},
		async updateHead() {
			/* const res = await changePhoto({ photoMedia: this.head, fileName: '头像' })
			if (res.data.success) {
				this.getUserInfo('reflash')

				this.$message.success('头像修改成功')
				this.newHead = '';
			}
			else {
				this.$message.error(res.data.message)
			} */
			const base64 = this.head;
			const file = this.base64ToBlob(base64);
			//const file = this.blobToFile(blob, 'avatar.jpeg');
			const formData = new FormData();
			formData.append('file', file);
			const res = await uploadUserPhoto(formData);
			if(res.code === 200 || res.code === 204) {
				await this.getUserInfo('reflash')
				this.$message.success('头像修改成功')
				this.newHead = '';
				
			}
		},
		async getUserInfo(e) {
			const res = await getUserInfo()
			if(!res.data?.email) {
				this.directionMask = true;
			}
			if (res.success) {
				this.$store.commit('setUserInfo', res.data)
			}
			if(e) {
				if(this.decryptUserInfo) {
					this.toggleInfo();
				}
			}
		},
		upPass() {
			this.upPassVisible = true;
		},
		onUpPass() {
			this.upPassVisible = false;
		},
		upEmail() {
			this.upEmailVisible = true;
		},
		onUpEmail() {
			this.getUserInfo();
			this.upEmailVisible = false;
		},
		upPos() {
			this.posId = this.userInfo.posId
			this.upPosVisible = true;
		},
		onUpPos() {
			this.getUserInfo();
			this.upPosVisible= false;
		},
		onUpOrg() {
			this.getUserInfo();
			this.upOrgVisible = false;
		},
		directionMaskClick() {
			this.directionMask = false;
		},
		// base64转blob
        base64ToBlob(base64Data) {
            //let arr = base64Data.split(','),
            //fileType = arr[0].match(/:(.*?);/)[1],
			//window.atob
			console.log(base64Data.split(','))
            let bstr = window.atob(base64Data.split(',')[1]);
            let l = bstr.length;
            let u8Arr = new Uint8Array(l);
            while (l--) {
                u8Arr[l] = bstr.charCodeAt(l);
            }
            return new File([u8Arr], 'avatar.jpeg', {
                type: 'image/jpeg',
				lastModifiedDate : new Date()
            });
        },
        // blob转file
        blobToFile(newBlob, fileName) {
			
            newBlob.lastModifiedDate = new Date();
            newBlob.name = fileName;
			//console.log(new File(newBlob, fileName));
			return newBlob
			//console.log(newBlob)
            //return new File([newBlob], fileName, { type: 'image/jpeg',  lastModifiedDate: new Date() });
        },
		upOrg() {
			this.upOrgVisible = true;
		},
		async toggleInfo() {
			if(this.isLoadingInfo) {
				return 
			}
			// 目前是显示的情况下就隐藏
			if(this.isShowInfo) {
				this.isShowInfo = false
			}
			// 目前隐藏且已存在未脱敏信息则直接显示
			else if(!this.isShowInfo && this.decryptUserInfo) {
				this.isShowInfo = true
			}
			// 隐藏且不存在脱敏信息
			else {
				this.isLoadingInfo = true;
				const res = await getDecryptUserInfo();
				if(res.success) {
					this.decryptUserInfo = res.data
					this.isShowInfo = true;
				}
				this.isLoadingInfo = false;
			}
		}
	}
}
</script>

<style scoped>
.title {
	font-size: 18px;
	color: rgba(0, 0, 0, 0.85);
	text-align: left;
	border-bottom: 1px solid #EBEBEB;
	margin: 0 16px;
	padding: 16px 0;
	font-weight: 550
}

.box {
	width: 600px;
	display: flex;
	justify-content: space-between;
}
</style>

<style lang="less" scoped>
.modify-avatar-btn {
	background: @srims-primary-color;
}
</style>

