<template>
	<a-modal :visible.sync="visible" title="修改密码" :width="600" @cancel="handleCancel">
		<a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
			<a-form-model-item label="旧密码" has-feedback prop="oldPass">
				<a-input v-model="ruleForm.oldPass" type="password" placeholder="请输入旧密码" autocomplete="off">
				</a-input>
			</a-form-model-item>
			<a-form-model-item label="新密码" has-feedback prop="pass">
				<a-input-password v-model="ruleForm.pass" type="password" placeholder="请输入新密码" autocomplete="off">
				</a-input-password>
			</a-form-model-item>
			<a-form-model-item label="确认新密码" has-feedback prop="checkPass">
				<a-input-password v-model="ruleForm.checkPass" type="password" placeholder="再次输入新密码" autocomplete="off">
				</a-input-password>
			</a-form-model-item>
			<a-form-model-item label="短信验证码" has-feedback prop="smsCode" class="smsInput">
				<div style="display: flex; padding-right: 32px;">
					<div style="width: 0px; flex: 1;">
						<a-input v-model="ruleForm.smsCode" placeholder="请输入验证码" autocomplete="off">
						</a-input>
					</div>
					<div style="margin-left: 16px;">
						<a-button :disabled="disabled" class="sms" @click="sendSms">{{ smsTxt }}
						</a-button>
					</div>
				</div>
			</a-form-model-item>
		</a-form-model>
		<template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel" >
                    取消
                </a-button>
                <a-button  :loading="loading" :class="[loading ? '' : 'confirm-btn']" type="primary" :disabled="loading" @click="submit">
                    确定
                </a-button>
            </div>
        </template>
	</a-modal>
</template>

<script>
import { setEncrypt } from '../../tool/encryption.js'

import { sendPhoneCodebyChangePwd, changePwd, getPublicRsaKey } from '../../api/anotherIndex'

export default {
	props: ['hide', 'visible'],
	data() {
		return {
			smsTxt: '发送验证码',
			disabled: false,
			ruleForm: {
				smsCode: '',
				phone: '',
				oldPass: '',
				pass: '',
				checkPass: ''
			},
			layout: {
				labelCol: {
					span: 7
				},
				wrapperCol: {
					span: 13
				},
			},
			rules: {
				phone: [{
					required: true,
					message: '手机号码不能为空',
					trigger: 'blur'
				}],
				pass: [{
					required: true,
					//message: '密码不能为空',
					trigger: 'blur',
					validator: this.passwordValidator
				}],
				oldPass: [{
					required: true,
					message: '旧密码不能为空',
					trigger: 'blur'
				}, /* {
					min: 6,
					message: '密码不小于6位'
				} */],
				checkPass: [{
					required: true,
					//message: '确认密码不能为空',
					trigger: 'blur',
					validator: this.confirmPasswordValidator
				}],
				smsCode: [{
					required: true,
					message: '短信验证码不能为空',
					trigger: 'blur'
				}],
			},
			loading: false
		}
	},

	created() {

	},
	methods: {
		async sendSms() {
			this.smsTime = 60
			const res = await sendPhoneCodebyChangePwd()
			if (res.success) {
				this.$message.success('验证码已发送，请注意查收')
				this.timer()
			}
		},
		timer() {
			if (this.smsTime > 0) {
				this.disabled = true
				this.smsTime--
				this.smsTxt = this.smsTime + "秒再发"
				setTimeout(this.timer, 1000)
			} else {
				this.smsTime = 0
				this.smsTxt = "获取验证码"
				this.disabled = false
			}
		},
		submit() {
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					const resd = await getPublicRsaKey()
					if (resd.success) {
						const rsaKey = setEncrypt(resd.data, this.ruleForm.pass)
						let data = {
							newPassword: rsaKey,
							code: this.ruleForm.smsCode,
							password: setEncrypt(resd.data, this.ruleForm.oldPass),
						}
						const res = await changePwd(data)
						if (res.success) {
							this.$message.success('修改密码成功,请重新登录')
							this.$emit('onUpPass')
							this.$store.commit('logout')
							window.localStorage.clear()
							this.$router.push({ path: '/login' })
						}
					}
				} else {
					console.log('error submit!!');
					return
				}
			});
		},
		// 密码校验器
		passwordValidator(_rule, value, callback) {
			if (!value.toString()) {
				callback(new Error('请输入密码'));
				return
			}
			const reg = /[^\x00-\xff]/g
			const hasChinese =  reg.test(value);
			if(hasChinese) {
				callback(new Error('不能包含中文字符'))
			}
			let re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+\-={}:;<>,.?])[A-Za-z\d~!@#$%^&*()_+\-={}:;<>,.?]{6,15}/;
			const flag = re.test(value);
			if (flag) {
				this.$refs.ruleForm.validateField('checkPass');
				callback();
			}
			else {
				callback(new Error('密码必须为6-15为包含大小写字母、数字、和特殊字符'));
			}
		},
		// 确认密码校验器
		confirmPasswordValidator(_rule, value, callback) {
			if (!value) {
				callback(new Error('请确认密码'))
			}
			if (value === this.ruleForm.pass) {
				callback();
			}
			else {
				callback(new Error('两次输入密码不一致'))
			}
		},
		handleCancel() {
			this.$emit('update:visible', false);
		}
	}
}
</script>

<style lang="less" scoped>
.sms {
	width: 88px;
	height: 32px;
	border-radius: 4px;
	background: #F0F5FF;
	font-size: 14px;
	color: @srims-primary-color;
	border-color: transparent ;
	padding-left: 8px;
	&:hover {
		border-color: @srims-primary-color;
	}
}
</style>
<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    width: 100%;

    .confirm-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
    }

    .concel-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
        background: #EEEEEE;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 24px;
        &:hover {
            opacity: 0.8;
        }
    }
}
</style>