<template>
	<div>
		<a-modal :width="600" :visible="visible" title="修改邮箱" @cancel="handleCancel">
			<div style="text-align: left;">
				<a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
					<a-form-model-item label="新邮箱" has-feedback prop="email">
						<a-input v-model="ruleForm.email" placeholder="请输入新邮箱" autocomplete="off">
						</a-input>
					</a-form-model-item>
					<a-form-model-item label="验证方式">
						<a-select v-model="validateType">
							<a-select-option value="1" key="1">
								手机验证码
							</a-select-option>
							<a-select-option value="2" key="2">
								新邮箱验证码
							</a-select-option>
						</a-select>
					</a-form-model-item>
					<a-form-model-item label="验证码" has-feedback prop="smsCode" class="smsInput">
						<div style="display: flex; padding-right: 32px;">
							<div style="width: 0; flex: 1;">
								<a-input  v-model="ruleForm.smsCode" placeholder="请输入验证码" autocomplete="off">
								</a-input>
							</div>
							<div style="margin-left: 16px;">
								<a-button :disabled="disabled" class="sms" @click="sendSms">{{ smsTxt }}
								</a-button>
							</div>
						</div>
					</a-form-model-item>
				</a-form-model>
			</div>
			<template slot="footer">
				<div class="btns-ctn">
					<a-button class="concel-btn" :disabled="loading" :loading="loading" @click="handleCancel">
						取消
					</a-button>
					<a-button type="primary" class="confirm-btn" :disabled="loading" :loading="loading" @click="submit">
						确定
					</a-button>
				</div>
			</template>
		</a-modal>
	</div>
</template>

<script>
import { sendCodeFromEmail, updateEmail } from '../../api/anotherIndex'
export default {
	props: ['hide', 'visible'],
	data() {
		let validatorEmail = (rule, value, callback) => {
			// 如果为空值，就抛出错误
			var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
			if (!value) {
				callback(new Error("邮箱不能为空!"))
			} else {
				// 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
				if (!reg.test(value)) {
					callback(new Error("邮箱格式不正确!"));
				} else {
					callback();
				}
			}
		}
		return {
			validateType: '1', 	// 验证方式
			smsTxt: '发送验证码',
			disabled: false,
			ruleForm: {
				smsCode: '',
				email: '',
			},
			layout: {
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 13
				},
			},
			rules: {
				email: [{
					required: true,
					validator: validatorEmail,
					trigger: 'blur'
				}],

				smsCode: [{
					required: true,
					message: '验证码不能为空',
					trigger: 'blur'
				}],
			},
			loading: false
		}
	},
	components: {
	},
	methods: {
		sendSms() {
			this.$refs.ruleForm.validateField(["email"], async (valid) => {
				if (valid == '') {
					this.smsTime = 60
					//let data = "?Email=" + encodeURIComponent(this.ruleForm.email) + "&SendMode=" + this.validateType;
					const res = await sendCodeFromEmail({
						Email: this.ruleForm.email,
						SendMode: this.validateType
					})
					if (res.success) {
						this.$message.success('验证码已发送，请注意查收')
						this.timer()
					}
				} else {
					console.log('error submit!!');
					return
				}
			})
		},
		timer() {
			if (this.smsTime > 0) {
				this.disabled = true
				this.smsTime--
				this.smsTxt = this.smsTime + "秒再发"
				setTimeout(this.timer, 1000)
			} else {
				this.smsTime = 0
				this.smsTxt = "获取验证码"
				this.disabled = false
			}
		},
		submit() {
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					let data = {
						email: this.ruleForm.email,
						code: this.ruleForm.smsCode
					}
					const res = await updateEmail(data)
					if (res.success) {
						this.$message.success('修改邮箱成功')
						/* this.$parent.emailLoad = false
						this.$parent.getUserInfo('reflash') */
						this.$emit('onUpEmail')
					}
				} else {
					console.log('error submit!!');
					return
				}
			});
		},
		handleCancel() {
			this.$emit('update:visible', false);
		}
	}
}
</script>

<style lang="less" scoped>
.sms {
	width: 88px;
	height: 32px;
	border-radius: 4px;
	background: #F0F5FF;
	font-size: 14px;
	color: @srims-primary-color;
	border-color: transparent ;
	padding-left: 8px;
	&:hover {
		border-color: @srims-primary-color;
	}
}
</style>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    width: 100%;

    .confirm-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
    }

    .concel-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
        background: #EEEEEE;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 24px;
        &:hover {
            opacity: 0.8;
        }
    }
}
</style>
