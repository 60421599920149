<template>
	<a-modal :visible="visible" title="修改职位" :width="600" @cancel="handleCancel">
		<a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
			<a-form-model-item label="职务/职位" has-feedback prop="pos">
				<a-select placeholder="请选择职务/职位" v-model="ruleForm.pos">
					<a-select-option :value="item.value" :key="item.value" v-for="(item, index) in posData">
						{{ item.text }}
					</a-select-option>
				</a-select>
			</a-form-model-item>
		</a-form-model>
		<template slot="footer">
            <div class="btns-ctn">
                <a-button :disabled="loading" class="concel-btn" @click="handleCancel" >
                    取消
                </a-button>
                <a-button  :loading="loading" :class="[loading ? '' : 'confirm-btn']" type="primary" :disabled="loading" @click="submit">
                    确定
                </a-button>
            </div>
        </template>
	</a-modal>
</template>

<script>
import {
	posInfoInit,
	updatePos
} from '../../api/anotherIndex'
export default {
	props: ['posId', 'visible'],
	data() {
		return {
			posData: [],
			ruleForm: {
				pos: ''
			},
			layout: {
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 13
				},
			},
			rules: {
				pos: [{
					required: true,
					message: '职务/职位不能为空',
					trigger: 'blur'
				}],
			},
			loading: false
		}
	},
	created() {
		this.posInfoInit();
	},
	beforeDestroy() {
	},
	methods: {
		async posInfoInit() {
			const res = await posInfoInit()
			if (res.success) {
				this.posData = res.data.posDataList
				this.ruleForm.pos = this.posId === 0 ? undefined : this.posId;
			}
		},
		submit() {
			this.loading = true;
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					const res = await updatePos({
						posId: this.ruleForm.pos
					})
					if (res.success) {
						this.$message.success('修改职位成功')
						this.$emit('onUpPos')
						/* this.$parent.upPosLoad = false
						this.$parent.getUserInfo('reflash') */
					} 
				} else {
					console.log('error submit!!');
					return
				}
				this.loading = false;
			});
		},
		handleCancel() {
			this.$emit('update:visible', false);
		}
	}
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    width: 100%;

    .confirm-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
    }

    .concel-btn {
        width: 88px;
        height: 32px;
        border: 0 !important;
        background: #EEEEEE;
        color: rgba(0, 0, 0, 0.65);
        margin-right: 24px;
        &:hover {
            opacity: 0.8;
        }
    }
}
</style>